.toolbar {
  position: relative;
  width: 150px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.toolbar > button {
  margin: 5px;
}
