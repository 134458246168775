.toolbar__container {
  position: absolute;
  right: 20px;
  top: 200px;
  z-index: 500;
}

.titleContainer {
  position: absolute;
  z-index: 500;

  display: flex;
  flex-direction: row;

  width: 100%;

  justify-content: center;
  align-items: center;
}

.title {
  font-size: 35px;
  font-weight: 700;
}
